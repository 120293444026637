import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

// Define an interface for props that include a name and a variant
interface WithProps {
    name: string
    variant: string
    onClick?: () => void
}

// Create a store for Outseta user data
const useOutsetaStore = createStore({
    userData: null,
})

// Define constant for the total number of lessons in the course
const numberOfLessons = 20

// Define constants for the different lesson status variants
const loadingVariantName = "Loading"
const notStartedVariantName = "Not started"
const finishedVariantName = "Finished"

// This function is an override that adds a 'variant' prop to a component based on the lesson status in the Outseta store
export function withLessonStatus(
    Component: ComponentType<WithProps>
): ComponentType<WithProps> {
    return (props) => {
        const [store, setStore] = useOutsetaStore()
        let variant = loadingVariantName
        // Extract the lesson number from props.name
        const lessonNumber = props.name.match(/\d+/)[0]

        if (store.userData) {
            variant = store.userData.Account[`Lesson${lessonNumber}Status`]
        }

        return (
            <Component
                {...props}
                variant={variant}
                data-outseta-button="status"
            />
        )
    }
}

// This function updates the status of a lesson in both Outseta and the store
function updateLessonStatus(lessonNumber, newStatus, store, setStore) {
    // Check if window object exists (i.e., code is running in the browser)
    if (typeof window !== "undefined") {
        // Check if status has to be changed
        if (
            store.userData.Account[`Lesson${lessonNumber}Status`] != newStatus
        ) {
            let Outseta = window["Outseta"]

            Outseta.getUser()
                .then((userData) => {
                    // Update the corresponding lesson status in Outseta
                    userData.Account[`Lesson${lessonNumber}Status`] = newStatus
                    userData.update(userData)

                    // Update the corresponding lesson status in the store
                    setStore({
                        userData: {
                            ...store.userData,
                            Account: {
                                ...store.userData.Account,
                                [`Lesson${lessonNumber}Status`]: newStatus,
                            },
                        },
                    })

                    console.log(
                        `Status of Lesson #${lessonNumber} set to ${newStatus}`
                    )
                })
                .catch((error) => {
                    // Handle any errors that occurred while fetching user data
                    console.error(
                        "An error occurred while updating lesson status:",
                        error
                    )
                })
        } else {
            console.log(
                `Status of Lesson #${lessonNumber} is already set to ${newStatus}`
            )
        }
    }
}

// This function is an override that adds an onClick handler to a component
// The handler sets the status of a lesson in Outseta and the store to 'Finished'
export function withLessonFinishedButton(
    Component: ComponentType<WithProps>
): ComponentType<WithProps> {
    return (props) => {
        const [store, setStore] = useOutsetaStore()

        const handleClick = () => {
            // Extract the lesson number from props.name
            const lessonNumber = props.name.match(/\d+/)[0]

            // Update the lesson status to 'Finished'
            updateLessonStatus(
                lessonNumber,
                finishedVariantName,
                store,
                setStore
            )
        }

        return (
            <div onClick={handleClick} data-outseta-button="finish">
                <Component {...props} />
            </div>
        )
    }
}

// This function is an override that adds an onClick handler to a component
// The handler sets the status of a lesson in Outseta and the store to 'Not Started'
export function withLessonResetButton(
    Component: ComponentType<WithProps>
): ComponentType<WithProps> {
    return (props) => {
        const [store, setStore] = useOutsetaStore()

        const handleClick = () => {
            // Extract the lesson number from props.name
            const lessonNumber = props.name.match(/\d+/)[0]

            // Update the lesson status to 'Not Started'
            updateLessonStatus(
                lessonNumber,
                notStartedVariantName,
                store,
                setStore
            )
        }

        return (
            <div onClick={handleClick} data-outseta-button="reset">
                <Component {...props} />
            </div>
        )
    }
}

// This function is an override that fetches user data from Outseta when a component is first rendered
// If the user's account is not initialized, it initializes the status of each lesson to 'Not started'
export function withOutsetaData(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useOutsetaStore()

        // Check if window object exists (i.e., code is running in the browser)
        if (typeof window !== "undefined") {
            let Outseta = window["Outseta"]

            // If Outseta is not defined, just return the component without the data
            if (!Outseta) {
                console.warn(
                    "Outseta is not defined. Returning the component without data."
                )
                return <Component {...props} />
            }

            if (!store.userData) {
                Outseta.getUser()
                    .then((userData) => {
                        // Update the store with the fetched user data
                        setStore({ userData })
                        console.log("User data fetched!")

                        return userData
                    })
                    .then((userData) => {
                        if (!userData.Account.AccountInit) {
                            console.log(
                                "Account not initialized, starting initialization…"
                            )

                            Outseta.getUser()
                                .then(function (user) {
                                    // Initialize lessons
                                    for (let i = 1; i <= numberOfLessons; i++) {
                                        userData.Account[`Lesson${i}Status`] =
                                            notStartedVariantName
                                    }

                                    userData.Account.AccountInit = true
                                    userData.update(userData)
                                    return userData
                                })
                                .then((userData) => {
                                    setStore({ userData })
                                    console.log(
                                        "Account initialization finished"
                                    )
                                })
                                .catch((error) => {
                                    // Handle any errors that occurred while fetching user data
                                    console.error(
                                        "An error occurred while initializing user data:",
                                        error
                                    )
                                })
                        } else {
                            console.log("Account already initialized")

                            // For testing purposes
                            /* Outseta.getUser().then(function (user) {
                                user.Account.AccountInit = false
                                user.update(user)
                            }) */
                        }
                    })
                    .catch((error) => {
                        // Handle any errors that occurred while fetching user data
                        console.error(
                            "An error occurred while fetching user data:",
                            error
                        )
                    })
            }
        }

        return <Component {...props} />
    }
}
